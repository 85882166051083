import { useState, useEffect } from "react";

import './Agricultural.css';

import { SvgArrow, SvgArrowFile, SvgClose, SvgHome } from "components/Svg";

import { GetDataPage } from "interface/Data";

export default function MesaPartsAndServices_Agricultural(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('partsAndServices').agricultural);
    const [ page, setPage ]         = useState('index');

    const [ pageDetails, setPageDetails ] = useState('');
    const [ namePage, setNamePage ]       = useState('');

    const [ colheitaStatus, setColheitaStatus ] = useState(false);

    const [ pagePul, setPagePul ] = useState('');
    const [ namePageOrigin, setNamePageOrigin ] = useState('');

    const [ next, setNext ] = useState('');
    const [ prev, setPrev ] = useState('');

    const [ status, setStatus ] = useState(false);
    
    const [ openIndex, setOpenIndex ]   = useState('');
    const [ openFile, setOpenFile ]     = useState('');
    const [ statusFile, setStatusFile ] = useState(false);

    let countLeft  = 0;
    let countRight = 0;
    let countImg   = 0;

    useEffect(()=>{
        ShowDataPage();
    }, []);

    useEffect(()=>{
        ShowDataPage();
    }, [page]);

    useEffect(()=>{
        ShowDataPage();
    }, [pageDetails]);

    function ZeraClick(){
        setPageDetails('');
        setNamePage('');
        setColheitaStatus(false);
        setPagePul('');
        setNamePageOrigin('');
        setNext('');
        setPrev('');
        setStatus(false);
        setOpenIndex('')
        setOpenFile('');
        setStatusFile(false);
    }

    function ShowDataPage(){
        switch (page) {
            case 'index':
                return(
                    <>
                        <div className="div_img" style={ { display: "contents" } }>    
                            <div className="div_topic_show_data_buttons data_buttons">
                                <div className="return" onClick={ ()=>{ props.ClickPage('index'); ZeraClick() } }>
                                    <SvgHome className="icon_home" color="#FFDD00" />
                                </div>
                                <div className="name_current_page">
                                    <div className="div_button btn_agr">
                                        <div className="button btn_1">
                                            <div className="show_name button_1">Agrícola</div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>  
                        <div className="btn_name_page">            
                            <div className="title_">
                                <img alt="name" src="./assets/name_part_service.svg" className="svg_name" />
                            </div> 
                        </div>
                        <div className="div_show_mandala">
                            <div className="circle_rotate">
                                <div className="div_click_button">
                                    <div className="btn_1" onClick={ ()=>{ setPage('preparo'); } } />
                                    <div className="btn_2" onClick={ ()=>{ setPage('plantio') } } />
                                    <div className="btn_3" onClick={ ()=>{ setColheitaStatus(true) } } />
                                    <div className="btn_4" onClick={ ()=>{ setPage('tratos_culturais') } } />
                                    <img alt="img" src="./assets/PartsAndServices/btn.png" className="img_mandala" />
                                </div>
                                <div className="title_circle" onClick={ ()=>{ setPage('planning_construction') } }>
                                    Planejamento
                                </div>
                            </div>
                        </div>
                        {
                            colheitaStatus == false ? null :
                            <div className="div_machine">
                                <div className="list_machine">
                                    <div className="close_modal" onClick={ ()=>{ setColheitaStatus(false); } }>
                                        <SvgClose className="icons" color="#387C2B" />
                                    </div>
                                    <div className="show_details" onClick={ ()=>{ setPage('colheita_algodao'); setColheitaStatus(false) } }>
                                        <div className="mach_subtitle">Colheita</div>
                                        <div className="mach_title">Algodão</div>
                                        <div className="mach_div_img">
                                            <img alt="img" src="./assets/PartsAndServices/colhe_1.jpg" className="img_machine" />
                                        </div>
                                    </div>
                                    <div className="show_details" onClick={ ()=>{ setPage('colheita_graos'); setColheitaStatus(false) } }>
                                        <div className="mach_subtitle">Colheita</div>
                                        <div className="mach_title">Grãos</div>
                                        <div className="mach_div_img">
                                            <img alt="img" src="./assets/PartsAndServices/colhe_2.jpg" className="img_machine" />
                                        </div>
                                    </div>
                                    <div className="show_details" onClick={ ()=>{ setPage('colheita_cana'); setColheitaStatus(false) } }>
                                        <div className="mach_subtitle">Colheita</div>
                                        <div className="mach_title">Cana</div>
                                        <div className="mach_div_img">
                                            <img alt="img" src="./assets/PartsAndServices/colhe_3.jpg" className="img_machine" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                );
            
            default:
                return ListDataClick();
        }
    }

    function ListDataClick(){
        const showDataPage = dataPage[page];        
        const countButtonsLeft  = showDataPage.button.filter(item =>item.position === 'left');
        const countButtonsRight = showDataPage.button.filter(item =>item.position === 'right');

        return(
            <div className="div_data list_details">
                <div className="div_topic_show_data_buttons">
                    <div className="return" onClick={ ()=>{ props.ClickPage('index'); ZeraClick() } }>
                        <SvgHome className="icon_home" color="#FFDD00" />
                    </div>
                    <div className="name_current_page">
                        <div className="show_name">/</div>
                        {
                            pageDetails == 'list_details' ? 
                            <>
                                <div className="show_name name_click" onClick={ ()=>{ setPage('index'); ZeraClick() } }>
                                    Agrícola
                                </div>
                                <div className="show_name">/</div>
                                <div className="show_name">Peças e serviços</div>
                            </> :
                            <>        
                                {
                                    pageDetails === 'Pulverização' ? 
                                    <>                    
                                        <div className="show_name name_click" onClick={ ()=>{ setPage(page); ZeraClick() } }>
                                                Agrícola
                                        </div>
                                        <div className="show_name">/</div>
                                        <div className="show_name name_click" onClick={ ()=>{ setPage('tratos_culturais'); ZeraClick() } }>Tratos culturais</div>
                                        {
                                            pagePul == '' ? null :
                                            <>
                                                <div className="show_name">/</div>
                                                <div className="show_name">{ pageDetails }</div>
                                            </>
                                        }
                                    </> :
                                    <>                        
                                        <div className="show_name name_click" onClick={ ()=>{ setPage('index'); ZeraClick() } }>
                                            Peças e serviços
                                        </div>         
                                        <div className="show_name">/</div>
                                        <div className="show_name name_click" onClick={ ()=>{ setPage(page); ZeraClick() } }>{ dataPage[page].name }</div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
                <div className="show_data_mandala">
                    {
                        namePage == '' ?
                        <>
                            {
                                pageDetails == '' ?
                                <>                                    
                                    <div className="show_buttons">
                                        <div className="div_buttons">
                                            {
                                                showDataPage.button.map((key, index)=>{
                                                    if(key.position == 'left'){
                                                        countLeft = countLeft + 1;
                                                        return(
                                                            <div className={ "alt_width name_button border_left left_" + countLeft } key={ index } onClick={ ()=>{ setNamePage(key.name); setPrev(index - 1); setNext(index + 1) } }>
                                                                <span className="space_name" dangerouslySetInnerHTML={ { __html: key.name ? key.name.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                        <div className="div_buttons">
                                            {
                                                showDataPage.button.map((key, index)=>{
                                                    if(key.position == 'right'){
                                                        countRight = countRight + 1;
                                                        return(
                                                            <div className={ "alt_width name_button border_right right_" + countRight } key={ index } onClick={ ()=>{ setNamePage(key.name); setPrev(index - 1); setNext(index + 1) } }>
                                                                <span className="space_name" dangerouslySetInnerHTML={ { __html: key.name ? key.name.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                page == 'tratos_culturais' ? 
                                                <div className={ countButtonsRight.length > 5 ? "alt_width name_button border_right right_" + countRight : "name_button border_right right_" + countRight } onClick={ ()=>{ setPageDetails('Pulverização'); } }>
                                                    <span className="space_name">Pulverização</span>
                                                </div> : null
                                            }
                                        </div>
                                    </div>
                                    <div className="div_img">
                                        <div className="show_title">{ showDataPage.name }</div>
                                        <img alt="img" src={ showDataPage.img } className="bg_img" />
                                    </div>
                                </>
                                :
                                <>                                 
                                    <div className="show_buttons">
                                        <div className="div_buttons">
                                            {
                                                dataPage['pulverizacao'].button.map((key, index)=>{
                                                    if(key.position == 'left'){
                                                        countLeft = countLeft + 1;
                                                        return(
                                                            <div className={ "alt_width name_button border_left left_" + countLeft } key={ index } onClick={ ()=>{ setNamePage(key.name); setPrev(index - 1); setNext(index + 1); setPagePul({ "position": "left", "id": key.id }) } }>
                                                                <span className="space_name" dangerouslySetInnerHTML={ { __html: key.name ? key.name.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                        <div className="div_buttons">
                                            {
                                                dataPage['pulverizacao'].button.map((key, index)=>{
                                                    if(key.position == 'right'){
                                                        countRight = countRight + 1;
                                                        return(
                                                            <div className={ "alt_width name_button border_right right_" + countRight } key={ index } onClick={ ()=>{ setNamePage(key.name); setPrev(index - 1); setNext(index + 1); setPagePul({ "position": "right", "id": key.id }) } }>
                                                                <div className="space_name_pul">
                                                                    <div className="div_icon_btn_">
                                                                        <img alt="img" src={ key.icon } className="icon_btn_" />
                                                                    </div>
                                                                    <div className="" dangerouslySetInnerHTML={ { __html: key.name ? key.name.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                                </div>
                                                                {
                                                                    key.highlight == "Sim" ?
                                                                    <div className="highlight">
                                                                        <div>ideal para exactapply</div>
                                                                        <div className="highlight_circle" />
                                                                    </div> : null
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="div_img">
                                        <div className="show_title">{ dataPage['pulverizacao'].name }</div>
                                        <img alt="img" src={ dataPage['pulverizacao'].img } className="bg_img" />
                                    </div>
                                </>
                            }
                        </> :
                        ShowDetails()                     
                    }
                </div>
                
                <div className="div_example">
                    {
                        namePage == '' ? <img alt="img" src="./assets/cursor_pointer.png" className="div_example_img_example" /> : 
                        <>
                            {
                                pageDetails != '' ? null :
                                <>                                
                                    <div className="bg_data">
                                        <img alt="img" src="./assets/PartsAndServices/bg_data_agricultural.png" className="bg_data_img" />
                                    </div>
                                    {
                                        prev == '-1' ?
                                        <div className="return_stage stage_prev bg_green" onClick={ ()=>{ setNamePage(''); } }>
                                            <div className="return_arrow">
                                                <SvgArrow color="#FFDD00" />
                                            </div>
                                            <div className="return_name">
                                                { showDataPage.name }
                                            </div>
                                        </div> : 
                                        <div className="return_stage stage_prev bg_green" onClick={ ()=>{ setNamePage(showDataPage.button[prev].name); AltPrev(prev - 1, next - 1); } }>
                                            <div className="return_arrow">
                                                <SvgArrow color="#FFDD00" />
                                            </div>
                                            <div className="return_name">
                                                { showDataPage.button[prev].name }
                                            </div>
                                        </div>
                                    }
                                    {
                                        next >= showDataPage.button.length ? null :
                                        <div className="return_stage stage_next" onClick={ ()=>{ setNamePage(showDataPage.button[next].name); AltPrev(prev + 1, next + 1); } }>
                                            <div className="return_name">
                                                { showDataPage.button[next].name }
                                            </div>
                                            <div className="return_arrow">
                                                <SvgArrow color="#FFDD00" className="arrow_right" />
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        )
    }

    function PlayVideo(id_video, id_div, btn_video) {
        const play_ = document.getElementById(id_video);
        if (play_.paused){
            play_.play();
            document.getElementById(btn_video).style.opacity = "0";
            document.getElementById(id_div).style.opacity = "0";
        }else {
            play_.pause();
            document.getElementById(btn_video).style.opacity = "1";
            document.getElementById(id_div).style.opacity = "1";
        }
    }

    function AltPrev(btn_prev, btn_next) {
        setPrev(btn_prev); 
        setNext(btn_next);

        if(document.querySelectorAll('.div_stamp')){
            const addClass_ = document.querySelectorAll('.div_stamp');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }
        if(document.querySelectorAll('.div_text')){
            const addClass_ = document.querySelectorAll('.div_text');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }
        if(document.querySelectorAll('.mult_data')){
            const addClass_ = document.querySelectorAll('.mult_data');
            for (const addClass_alt of addClass_) {
                addClass_alt.classList.add('no_animation');
            }
        }

        setTimeout(() => {
            if(document.querySelectorAll('.div_stamp')){
                const addClass_ = document.querySelectorAll('.div_stamp');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.div_text')){
                const addClass_ = document.querySelectorAll('.div_text');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
            if(document.querySelectorAll('.mult_data')){
                const addClass_ = document.querySelectorAll('.mult_data');
                for (const addClass_alt of addClass_) {
                    addClass_alt.classList.remove('no_animation');
                }
            }
        }, 300);
    }

    function ShowDetails(){
        if(pageDetails === 'Pulverização'){
            const showData = dataPage['pulverizacao'].button.find(item => item.id == pagePul.id);
            console.log(showData);
            const showImg   = showData.data.find(item =>item.type == 'img');
            const showVideo = showData.data.filter(item =>item.type == 'video');
            const showText  = showData.data.find(item =>item.type == 'text');

            if(pagePul.position == 'left'){
                return(
                    <div className="pulv_data_left">
                        <div className="btn_">
                            <div className="title_fixed">
                                { showData.title }
                            </div>
                        </div>
                        <div className="show_file_example">
                            <img alt="img" src={ showData.data[0].file } className="img_example_pul" />
                        </div>
                    </div>
                )
            }else if(pagePul.position == 'right'){
                return(
                    <>
                        <div className="pulv_data_right">
                            <div className="btn_">
                                <div className="title_fixed">
                                    <div className="btn_subtitle">
                                        { showData.subtitle }
                                    </div>
                                    <div className="btn_title">
                                        { showData.title }
                                    </div>
                                </div>
                            </div>
                            <div className="pul_contents">
                                <div className="list_video">
                                    {
                                        showVideo.map((key, index)=>{
                                            return(
                                                <div className={ showVideo.length == 1 ? "div_video_full" : "div_video" } key={ index }>
                                                    <video width="100%" height="100%" controls={ false } loop={ true } autoPlay="autoplay" >
                                                        <source src={ key.file } type="video/mp4" />
                                                    </video>
                                                    <div className="name_video">{ key.title }</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>                                
                                <div className="list_text">
                                    <div className="div_text" dangerouslySetInnerHTML={ { __html: showText.text ? showText.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                </div>
                            </div>
                            <div className="img_fixed_right">
                                <img alt="img" src={ showImg.file } className="img_example_pul" />
                            </div>
                        </div>
                    </>
                )
            }
        }else {
            const showData   = dataPage[page].button.find(item => item.name == namePage);
            let countDivData = 0;

            let showText = '';
            if(showData.data.find(item => item.type == 'text')){
                showText = showData.data.find(item => item.type == 'text');  
            }

            let countImg    = 0;  
            let showDataImg = [];          
            if(showData.data.find(item => item.type == 'img')){
                showDataImg = showData.data.find(item => item.type == 'img');
                countImg    = showDataImg.img.length;
            }

            let countVideo    = 0;
            let showDataVideo = [];
            if(showData.data.find(item => item.type == 'video')){
                showDataVideo = showData.data.filter(item => item.type == 'video');
                countVideo = showDataVideo.length;
            }
            let total = countImg + countVideo;
            
            return(
                <>
                    <div className="name_page_click" id="show_data_page">
                        <div className="show_name_page_click bg_green">
                            <div>{ namePage }</div>
                            <div className={ showData.stamp_1 != '' && showData.stamp_2 != '' ? "stamp" : "stamp stamp_one" }>
                                {
                                    showData.stamp_1 == '' ? null :
                                    <div className="div_stamp">
                                        <img alt="img" src={ showData.stamp_1 } className="stamp_img" />
                                    </div>
                                }
                                {
                                    showData.stamp_2 == '' ? null :
                                    <div className="div_stamp">
                                        <img alt="img" src={ showData.stamp_2 } className="stamp_img" />
                                    </div>
                                }
                            </div>  
                        </div>  
                    </div>
                    <div className="div_show_contents_mandala">
                        {
                            showText.text ? 
                            <div className="div_text" dangerouslySetInnerHTML={ { __html: showText.text ? showText.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } /> : null
                        }
                        <div className={ showText.text ? total > 1 ? "mult_data" : "mult_data" : "mult_data mult_data_full" }>
                            {
                                showDataVideo.map((key, index)=>{    
                                    countDivData = countDivData + 1;                                
                                    return(
                                        <div className={ showText.text ? total == 1 ?  "div_video video_one data_" + countDivData : "div_video data_" + countDivData : "div_video video_full data_" + countDivData } key={ index }>
                                            <div className={ showText.text ? "img_example" : "img_example img_example_full" }>
                                                <div id={ "play_" + index } className="play" onClick={ ()=>{ PlayVideo('open_video_' + index + '', 'play_' + index + '', 'btn_video_' + index + ''); setStatus(!status) } }>
                                                    <div id={ "btn_video_" + index } className={ showText.text ? "img_play" : "img_play hide_play" }>
                                                        <img alt="img" src="./assets/OperationCenter/player.png" className="img_icon" />
                                                    </div> 
                                                    { 
                                                        showText.text ? 
                                                        <>                                                       
                                                            <img alt="img" src={ key.file } className="img_" />
                                                        </> : null
                                                    }
                                                </div>
                                                <div style={ { display: "flex" } }>  
                                                    {
                                                        showText.text ? 
                                                        <video id={ "open_video_" + index } width="100%" height="100%" controls={ false } loop={ true }>
                                                            <source src={ key.video } type="video/mp4" />
                                                        </video> :
                                                        <video id={ "open_video_" + index } width="100%" height="100%" controls={ false } loop={ true } autoPlay="autoplay">
                                                            <source src={ key.video } type="video/mp4" />
                                                        </video>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                countImg > 0 ? 
                                showDataImg.img.map((key, index)=>{
                                    countDivData = countDivData + 1;
                                    return(
                                        <div className={ countImg == 2 ? "div_img div_two data_" + countDivData : countImg == 1 ? "div_img div_one data_" + countDivData : "div_img div_three data_" + countDivData } key={ index } onClick={ ()=>{ setStatusFile(true); setOpenFile(index); } }>  
                                            <div className="search">
                                                <img alt="search" src="./assets/search.png" className="icon_search" />
                                            </div> 
                                            <img alt="img" src={ key } className="img_" />
                                        </div>
                                    )
                                }) : null
                            }
                        </div>
                    </div>
                    {
                        statusFile == false ? null :                    
                        <div className="div_parts_modal">
                            <div className="list_div_parts">
                                <div className="close_modal operation_center" onClick={ ()=>{ setStatusFile(false); setOpenFile(''); setOpenIndex(''); } }>
                                    <SvgClose className="icons" color="#FFDD00" />
                                </div>
                                {
                                    (openFile - 1) > 0 ? 
                                    <div className="prev_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenFile(openFile - 1) } }>
                                        <SvgArrowFile className="arrow_file" color="#FFFFFF" />
                                    </div> : null
                                }
                                {
                                    (openFile + 1) < countImg ?
                                    <div className="next_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenFile(openFile + 1) } }>
                                        <SvgArrowFile className="arrow_file next_icon" color="#FFFFFF" />
                                    </div> : null
                                }
                                <div className="show_details">
                                    <div className="mach_div_img">
                                        <img alt="img" src={ showDataImg.img[openFile] } className="img_div_parts" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            )
        }
    }

    return(
        <div className="MesaPartsAndServices_Agricultural">
            { ShowDataPage() }
        </div>
    )
}