import { useState, useEffect } from "react";

import './MesaUnimil.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";

import MesaUnimil_Home from "./Home";
import MesaUnimil_WhatIsIt from "./WhatIsIt";
import MesaUnimil_WhereToBuy from "./WhereToBuy";
import MesaUnimil_UnimilCana from "./UnimilCana";
import MesaUnimil_UnimilByJohnDeere from "./UnimilByJohnDeere";
import MesaUnimil_WhatAreTheBenefits from "./WhatAreTheBenefits";
import Unimil_NotificationPage from "./NotificationPage";

export default function MesaUnimil(props){

    const [ showData, setShowData ] = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('unimil'));
    const [ page, setPage ]         = useState('index');

    const [ widthTotal, setWidthTotal ] = useState(document.getElementById('root').clientWidth);

    useEffect(()=>{
        RegisterDataPage('unimil', setDataPage)
        CountShowData();
        TypePage();
        ChekedWidth();
    }, []); 

    useEffect(()=>{
        CountShowData();
        TypePage();
        ChekedWidth();
    }, [page]);

    useEffect(()=>{
        TypePage();
        ChekedWidth();
    }, [widthTotal]);

    function ChekedWidth(){
        const width = setInterval(() => {
            let numbWidth = document.getElementById('root').clientWidth;
            if(numbWidth <= 1023){
                setWidthTotal(numbWidth);
                clearInterval(width);
            }
        }, 600);
    }
    
    function CountShowData(){
        let count = 0;
        const countData = setInterval(() => {
            if(count == 1){
                setShowData(true); 
                clearInterval(countData);
            }
            count++; 
        }, 300);
    }

    function TypePage(){
        if(widthTotal >= 1024){
            switch (page) {
                case 'index':
                    return <MesaUnimil_Home ClickPage={ ClickPage } dataPage={ dataPage } />;
                
                case 'WhatIsIt':
                    return <MesaUnimil_WhatIsIt ClickPage={ ClickPage } />;

                case 'WhatAreTheBenefits':
                    return <MesaUnimil_WhatAreTheBenefits ClickPage={ ClickPage } />;
                
                case 'WhereToBuy':
                    return <MesaUnimil_WhereToBuy ClickPage={ ClickPage } />;

                case 'UnimilByJohnDeere':
                    return <MesaUnimil_UnimilByJohnDeere ClickPage={ ClickPage } />;

                case 'UnimilCana':
                    return <MesaUnimil_UnimilCana ClickPage={ ClickPage } />;
            }
        }else {            
            window.location.href = "https://unimil.com.br";
            // return <Unimil_NotificationPage />;
        }
    }

    function ClickPage(value){
        setPage(value);
    }

    return(
        <div className="MesaUnimil">
            <div className="reset_page" onClick={ ()=>{ props.ResetPage('index') } } />
            <div className={ showData == true ? "div_data show_data" : "div_data" }>
                {
                    TypePage()
                }
            </div>
            <div className="div_bg">
                <img alt="img" src="./assets/texture.png" className="texture" />
            </div>
        </div>
    )
}