import { useState, useEffect } from "react";

import './MesaPartsAndServices.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";

import MesaPartsAndServices_Agricultural from "./Agricultural";
import MesaPartsAndServices_Construction from "./Construction";
import PartsAndServices_NotificationPage from "./NotificationPage";

export default function MesaPartsAndServices(props){

    const [ showData, setShowData ]     = useState(false);
    const [ dataPage, setDataPage ]     = useState(GetDataPage('partsAndServices'));
    const [ page, setPage ]             = useState('index');
    const [ widthTotal, setWidthTotal ] = useState(document.getElementById('root').clientWidth);

    useEffect(()=>{
        RegisterDataPage('partsAndServices', setDataPage);

        CountShowData();
        TypePage();
        ChekedWidth();
    }, []); 

    useEffect(()=>{
        CountShowData();
        TypePage();
        ChekedWidth();
    }, [page]);
    
    function CountShowData(){
        let count = 0;
        const countData = setInterval(() => {
            if(count == 1){
                setShowData(true); 
                clearInterval(countData);
            }
            count++; 
        }, 300);
    }

    function ChekedWidth(){
        const width = setInterval(() => {
            let numbWidth = document.getElementById('root').clientWidth;
            if(numbWidth <= 1023){
                setWidthTotal(numbWidth);
                clearInterval(width);
            }
        }, 600);
    }

    function TypePage(){
        if(widthTotal >= 1024){            
            switch (page) {
                case 'index':
                    return (                    
                        <div className={ showData == true ? "div_data show_data" : "div_data" }>
                            <div className="div_bg_img">
                                <div className="bg_img">
                                    <div className="title">
                                        <img alt="name" src="./assets/name_part_service.svg" className="svg_name" />
                                    </div>
                                    <div className="div_button">
                                        <div className="button btn_1">
                                            <div className="show_name button_1" onClick={ ()=>{ setPage('agricultural_index') } }>Agrícola</div>
                                        </div>
                                        <div className="button btn_2">
                                            <div className="show_name button_2" onClick={ ()=>{ setPage('construction') } }>Construção</div>
                                        </div>
                                    </div>
                                    <img alt="img" src="./assets/PartsAndServices/bg_page.jpg" className="bg_img" />
                                </div>
                            </div>
                            <div className="div_example">
                                <img alt="img" src="./assets/cursor_pointer.png" className="div_example_img_example" />
                            </div>
                        </div>
                    );
                
                case 'agricultural_index':
                    return <MesaPartsAndServices_Agricultural ClickPage={ ClickPage } />;
                
                case 'construction':
                    return <MesaPartsAndServices_Construction ClickPage={ ClickPage } />;
            }
        }else {
            window.location.href = "https://www.deere.com.br/pt/pe%C3%A7as-e-servi%C3%A7os/pe%C3%A7as/";
            // return <PartsAndServices_NotificationPage />
        }
    }

    function ClickPage(value){
        setPage(value);
    }

    return(
        <div className="MesaPartsAndServices">
            <div className="reset_page" onClick={ ()=>{ props.ResetPage('index') } } />
            { TypePage() }
            <div className="div_bg">
                <img alt="img" src="./assets/texture.png" className="texture" />
            </div>
        </div>
    )
}