import { useState, useEffect } from "react";

import './UnimilByJohnDeere.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SvgArrowFile, SvgClose } from "components/Svg";

export default function MesaUnimil_UnimilByJohnDeere(props){
    
    const [ dataPage, setDataPage ] = useState(GetDataPage('unimil'));
    const [ page, setPage ]         = useState('index');

    const [ buttonsList, setButtonsList ] = useState([]);
    const [ qrCode, setQrCode ]           = useState('');

    const [ openFile, setOpenFile ]     = useState('');
    const [ statusFile, setStatusFile ] = useState(false);

    const [ prev, setPrev ] = useState(0);
    const [ next, setNext ] = useState(0);

    useEffect(()=>{
        RegisterDataPage('unimil', setDataPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('unimil'));

        InitialData('buttonsList');
        InitialData('qrCode');
    }, [dataPage]);

    function InitialData(type){
        setTimeout(() => {
            switch (type) {
                case "buttonsList":
                        setButtonsList(GetDataPage('unimil').catalog['list_1']);
                    break;

                case "qrCode":
                        setQrCode(GetDataPage('unimil').catalog['qrCode_1']);
                    break;
            }
        }, 1000); 
    }

    function CurrentData(){
        switch (page) {
            case 'index':
                return(
                    <div className="div_data">
                        <div className="div_topic_all">
                            <div className="div_home_page">
                                <div className="div_home" onClick={ ()=>{ props.ClickPage('index'); } }>
                                    <img alt="img" src="./assets/home.png" className="img_home" />
                                </div>
                                <div className="div_name_page">
                                    <div className="name_page">Catálago de peças</div>
                                </div>  
                            </div>              
                            <div className="div_logotipo">
                                <img alt="img" src="./assets/Unimil/logo.png" className="logotipo" />
                            </div>    
                        </div>
                        <div className="contents">
                            <div className="list_buttons">
                                {
                                    buttonsList.map((key, index)=>{
                                        return(
                                            <div className="show_buttons" key={ index } onClick={ ()=>{ setPage(key.id) } }>
                                                { key.title }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="show_qrcode">
                                <div className="div_show_qrcode">
                                    <div className="">
                                        Baixe o catálogo completo
                                    </div>
                                    <img alt="img" src={ qrCode } className="img_qrcode" />
                                </div>
                            </div>
                        </div>
                        <div className="div_cursor_pointer">
                            <img alt="img" src="./assets/cursor_pointer.png" className="cursor" />
                        </div>
                    </div>
                )

            default:
                const showDataId = dataPage.catalog['list_1'].find(item => item.id == page);
                return(
                    <div className="div_data">
                        <div className="div_topic_all">
                            <div className="div_home_page">
                                <div className="div_home" onClick={ ()=>{ setPage('index'); } }>
                                    <img alt="img" src="./assets/home.png" className="img_home" />
                                </div>
                                <div className="div_name_page">
                                    <div className="subtitle_page">{ dataPage.catalog['title'] }</div>
                                    <div className="name_page">{ showDataId.subtitle }</div>
                                </div>  
                            </div>              
                            <div className="div_logotipo">
                                <img alt="img" src="./assets/Unimil/logo.png" className="logotipo" />
                            </div>    
                        </div>
                        <div className="list_data_parts">                            
                            <div className="text" dangerouslySetInnerHTML={ { __html: showDataId.text ? showDataId.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                            <div className="img">
                                {
                                    showDataId.list.map((key, index)=>{
                                        return(
                                            <div className="show_img" key={ index } onClick={ ()=>{ setStatusFile(true); setOpenFile(index); setPrev(index); setNext(index); } }>
                                                <div className="subtitle">
                                                    { key.reference }
                                                </div>
                                                <div className="search icon_page_return">
                                                    <img alt="search" src="./assets/search.png" className="icon_search" />
                                                </div> 
                                                <img alt="img" src={ key.img == "" ? "./assets/loading.gif" : key.img } className={ key.img == "" ? "not_img" : "show_file_parts" } />
                                                <div className="title">
                                                    { key.name }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {
                            statusFile == false ? null :
                            <div className="div_parts_modal">
                                <div className="list_div_parts">
                                    <div className="close_modal unimil" onClick={ ()=>{ setStatusFile(false); setOpenFile('') } }>
                                        <SvgClose className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        (openFile - 1) >= 0 ? 
                                        <div className="prev_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenFile(openFile - 1) } }>
                                            <SvgArrowFile className="arrow_file" color="#FFFFFF" />
                                        </div> : null
                                    }
                                    {
                                        (openFile + 1) < showDataId.list.length ?
                                        <div className="next_file" style={ { color: "#f00"  }} onClick={ ()=>{ setOpenFile(openFile + 1) } }>
                                            <SvgArrowFile className="arrow_file next_icon" color="#FFFFFF" />
                                        </div> : null
                                    }
                                    <div className="name_reference">
                                        { showDataId.list[openFile].reference }
                                    </div>
                                    <div className="name_code">
                                        { showDataId.list[openFile].name }
                                    </div>
                                    <div className="show_details">
                                        <div className="mach_div_img">
                                            <img alt="img" src={ showDataId.list[openFile].img } className="img_div_parts" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="div_bg_color" />
                    </div>
                )
        }
    }
    
    return(
        <div className="MesaUnimil_UnimilByJohnDeere">
            { CurrentData() }
        </div>
    )
}