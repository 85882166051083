import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Axios from 'axios';

import { SetListData } from 'interface/Data';

const root = ReactDOM.createRoot(document.getElementById('root'));
// 'http://localhost/React_JS/agrishow_2023/mesa_plataforma/public/list_data.json',
// 'https://agrishow2023-mesa.chroma-garden.com/list_data.json',
Axios({
    url    : 'https://agrishow2023-mesa.chroma-garden.com/list_data.json',
    mode   : 'no-cors',
    method : 'POST'
})
.then(response => {
    
    SetListData(response.data);

    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );
});