import { useState, useEffect } from "react";

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import MesaUnimil from "components/MesaUnimil";
import MesaPartsAndServices from "components/MesaPartsAndServices";
import MesaUpgrades from "components/MesaUpgrades";
import MesaOperationCenter from "components/MesaOperationCenter";

export default function App() {

    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    let nameUrl = window.location.href.split("#"); 

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);
    }, []);

    useEffect(()=>{
        setCurrentPage(GetListPag('currentPage'));
    }, [currentPage]);

    useEffect(()=>{
        switch (nameUrl[1]) {
            case 'operationscenter': case 'unimil': case 'partsandservices': case 'upgrades':
                    PageClick(nameUrl[1]);
                break;
        }
        setCurrentPage(GetListPag('currentPage'));
    }, [nameUrl]);

    function PageClick(value){
        SetListPag('currentPage', value);
    }
    
    let countClick = 0;
    function ResetPage(value){
        countClick = countClick + 1;
        if(countClick == 3){
            window.location.reload(true);
        }
    }
    function CurrentPageData(){
        switch (currentPage) {
            case 'index':
                return(
                    <div className="list_table">
                        <a className="table" onClick={ ()=>{ PageClick('operationscenter'); } } href="/#operationscenter">
                            Operation center
                        </a>
                        <a className="table" onClick={ ()=>{ PageClick('unimil'); } } href="/#unimil">
                            Unimil
                        </a>
                        <a className="table" onClick={ ()=>{ PageClick('partsandservices'); } } href="/#partsandservices">
                            Peças e serviços
                        </a>
                        <a className="table" onClick={ ()=>{ PageClick('upgrades'); } } href="/#upgrades">
                            Upgrades
                        </a>
                    </div>
                );

            case "operationscenter":
                return <MesaOperationCenter PageClick={ PageClick } ResetPage={ ResetPage } />;

            case "unimil":
                return <MesaUnimil PageClick={ PageClick } ResetPage={ ResetPage } />;

            case "partsandservices":
                return <MesaPartsAndServices PageClick={ PageClick } ResetPage={ ResetPage } />;

            case "upgrades":
                return <MesaUpgrades PageClick={ PageClick } ResetPage={ ResetPage } />;
        }
    } 

    return CurrentPageData();
}